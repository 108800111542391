var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{attrs:{"id":"dashboard-analytics"}},[_c('b-row',{staticClass:"match-height"},[_c('b-col',{attrs:{"md":"4","offset":"4"}})],1),_c('b-row',[(_vm.iCan('emploees', 'write'))?_c('b-col',{attrs:{"md":"3"}},[_c('b-link',{attrs:{"to":{name: 'create-employee', params: {software: 'on'}}}},[_c('statistic-card-vertical',{attrs:{"color":"primary","icon":"LogInIcon","statistic":_vm.i18nT("Onboard"),"statistic-title":_vm.i18nT("Onboard new employee")}})],1)],1):_vm._e(),(_vm.iCan('offboarding', 'write'))?_c('b-col',{attrs:{"md":"3"}},[_c('b-link',{attrs:{"to":{name: 'offboarding', params: {id: 0}}}},[_c('statistic-card-vertical',{attrs:{"color":"primary","icon":"LogOutIcon","statistic":_vm.i18nT("Offboard"),"statistic-title":_vm.i18nT("Offboard employee")}})],1)],1):_vm._e(),(_vm.iCan('tasks', 'write'))?_c('b-col',{attrs:{"md":"3"}},[_c('a',{attrs:{"href":"/on/create-task"}},[_c('statistic-card-vertical',{attrs:{"color":"primary","icon":"CheckCircleIcon","statistic":_vm.i18nT("Task"),"statistic-title":_vm.i18nT("Create new task")}})],1)]):_vm._e(),_c('b-col',{attrs:{"md":"3"}},[_c('b-link',{attrs:{"to":{
          name: "inbox-new",
          query: { newMsg: 1}
        }}},[_c('statistic-card-vertical',{attrs:{"color":"primary","icon":"MailIcon","statistic":_vm.i18nT("Message"),"statistic-title":_vm.i18nT("Send new message")}})],1)],1)],1),_c('b-row',{staticClass:"match-height"},[(_vm.iCan('onboarding', 'read'))?_c('b-col',{attrs:{"lg":"4"}},[_c('b-card',{attrs:{"no-body":""}},[_c('b-card-header',[_c('b-card-title',{staticClass:"ml-25"},[_vm._v(" "+_vm._s(_vm.onboardings.count)+" "+_vm._s(_vm.i18nT("Onboarding"))+" ")])],1),_c('b-card-body',[_vm._l((_vm.onboardings.items),function(onboarding){return _c('b-media',{key:onboarding.Id,staticClass:"pb-50 pt-50 border-bottom",attrs:{"no-body":""}},[_c('b-media-aside',[_c('b-avatar',{staticClass:"badge-light-primary",attrs:{"src":onboarding.UserObj.ImageUrl,"text":(onboarding.UserObj.FirstName ? onboarding.UserObj.FirstName.charAt(0) : '') + (onboarding.UserObj.LastName ? onboarding.UserObj.LastName.charAt(0) : ''),"to":{
                    name: "view-employee",
                    params: {id: onboarding.UserId, software: 'on'}
                },"size":"38"}})],1),_c('b-media-body',{staticClass:"my-auto"},[_c('a',{staticClass:"font-weight-bold d-block text-nowrap",attrs:{"href":"/on/employees/view/"+onboarding.UserId}},[_vm._v(" "+_vm._s(onboarding.UserObj.Label)+" ")]),_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(onboarding.UserObj.Position)+" ")])])],1)}),(_vm.onboardings.count === 0)?_c('b-media',{staticStyle:{"text-align":"center"}},[_c('span',{staticClass:"b-avatar badge-light-secondary rounded mb-2 mt-4",staticStyle:{"width":"70px","height":"70px"}},[_c('feather-icon',{attrs:{"icon":"LogInIcon","size":"36"}})],1),_c('h5',[_vm._v(_vm._s(_vm.i18nT("Nothing new")))]),_c('p',{staticClass:"text-muted"},[_vm._v(" "+_vm._s(_vm.i18nT("There are no onboarding employees.")))])]):_vm._e()],2),_c('b-link',{staticStyle:{"padding":"8px"},attrs:{"to":{name: 'onboarding', params: {id: 0}}}},[_c('b-button',{staticStyle:{"width":"100%"},attrs:{"variant":"outline-secondary"}},[_vm._v(" "+_vm._s(_vm.i18nT("See all"))+" ")])],1)],1)],1):_vm._e(),(_vm.iCan('offboarding', 'read'))?_c('b-col',{attrs:{"lg":"4"}},[_c('b-card',{attrs:{"no-body":""}},[_c('b-card-header',[_c('b-card-title',{staticClass:"ml-25"},[_vm._v(" "+_vm._s(_vm.offboardings.count)+" "+_vm._s(_vm.i18nT("Offboarding"))+" ")])],1),_c('b-card-body',[_vm._l((_vm.offboardings.items),function(offboarding){return _c('b-media',{key:offboarding.Id,staticClass:"pb-50 pt-50 border-bottom",attrs:{"no-body":""}},[_c('b-media-aside',[_c('b-avatar',{staticClass:"badge-light-primary",attrs:{"src":offboarding.UserObj.ImageUrl,"text":(offboarding.UserObj.FirstName ? offboarding.UserObj.FirstName.charAt(0) : '') + (offboarding.UserObj.LastName ? offboarding.UserObj.LastName.charAt(0) : ''),"to":{
                    name: "view-employee",
                    params: {id: offboarding.UserId, software: 'on'}
                },"size":"38"}})],1),_c('b-media-body',{staticClass:"my-auto"},[_c('a',{staticClass:"font-weight-bold d-block text-nowrap",attrs:{"href":"/on/employees/view/"+offboarding.UserId}},[_vm._v(" "+_vm._s(offboarding.UserObj.Label)+" ")]),_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(offboarding.UserObj.Position)+" ")])])],1)}),(_vm.offboardings.count === 0)?_c('b-media',{staticStyle:{"text-align":"center"}},[_c('span',{staticClass:"b-avatar badge-light-secondary rounded mb-2 mt-4",staticStyle:{"width":"70px","height":"70px"}},[_c('feather-icon',{attrs:{"icon":"LogOutIcon","size":"36"}})],1),_c('h5',[_vm._v(_vm._s(_vm.i18nT("Nothing new")))]),_c('p',{staticClass:"text-muted"},[_vm._v(" "+_vm._s(_vm.i18nT("There are no offboarding employees.")))])]):_vm._e()],2),_c('b-link',{staticStyle:{"padding":"8px"},attrs:{"to":{name: 'offboarding', params: {id: 0}}}},[_c('b-button',{staticStyle:{"width":"100%"},attrs:{"variant":"outline-secondary"}},[_vm._v(" "+_vm._s(_vm.i18nT("See all"))+" ")])],1)],1)],1):_vm._e(),_c('b-col',{attrs:{"lg":"4"}},[_c('analytics-messages',{attrs:{"data":_vm.messages,"manageLink":"/on/inbox"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }