<template>
  <section id="dashboard-analytics">
    <b-row class="match-height">
      <b-col
        md="4"
        offset="4"
      >
        <!-- <analytics-congratulation /> -->
      </b-col>
    </b-row>
    <b-row>
      <b-col md="3"
        v-if="iCan('emploees', 'write')"
      >
        <b-link :to="{name: 'create-employee', params: {software: 'on'}}">
          <statistic-card-vertical
            color="primary"
            icon="LogInIcon"
            :statistic="i18nT(`Onboard`)"
            :statistic-title="i18nT(`Onboard new employee`)"
          />
        </b-link>
      </b-col>
      <b-col md="3"
             v-if="iCan('offboarding', 'write')"
      >
        <b-link :to="{name: 'offboarding', params: {id: 0}}">
          <statistic-card-vertical
            color="primary"
            icon="LogOutIcon"
            :statistic="i18nT(`Offboard`)"
            :statistic-title="i18nT(`Offboard employee`)"
          />
        </b-link>
      </b-col>
      <b-col md="3"
             v-if="iCan('tasks', 'write')"
      >
        <a href="/on/create-task">
          <statistic-card-vertical
            color="primary"
            icon="CheckCircleIcon"
            :statistic="i18nT(`Task`)"
            :statistic-title="i18nT(`Create new task`)"
          />
        </a>
      </b-col>
      <b-col md="3">
          <b-link :to="{
            name: `inbox-new`,
            query: { newMsg: 1}
          }">
          <statistic-card-vertical
            color="primary"
            icon="MailIcon"
            :statistic="i18nT(`Message`)"
            :statistic-title="i18nT(`Send new message`)"
          />
          </b-link>
      </b-col>
    </b-row>

    <b-row class="match-height">
      <b-col lg="4" v-if="iCan('onboarding', 'read')">
        <b-card no-body>
          <b-card-header>
            <b-card-title class="ml-25">
              {{onboardings.count}} {{i18nT(`Onboarding`)}}
            </b-card-title>
          </b-card-header>

          <!-- timeline -->
          <b-card-body>
            <b-media
              no-body
              v-for="onboarding in onboardings.items"
              :key="onboarding.Id"
              class="pb-50 pt-50 border-bottom"
            >
              <b-media-aside>
                <b-avatar
                  :src="onboarding.UserObj.ImageUrl"
                  :text="(onboarding.UserObj.FirstName ? onboarding.UserObj.FirstName.charAt(0) : '') + (onboarding.UserObj.LastName ? onboarding.UserObj.LastName.charAt(0) : '')"
                  :to="{
                      name: `view-employee`,
                      params: {id: onboarding.UserId, software: 'on'}
                  }"
                  class="badge-light-primary"
                  size="38"
                />
              </b-media-aside>
              <b-media-body class="my-auto">
                <a
                  :href="`/on/employees/view/`+onboarding.UserId"
                  class="font-weight-bold d-block text-nowrap"
                >
                  {{ onboarding.UserObj.Label }}
                </a>
                <p class="mb-0">
                  {{ onboarding.UserObj.Position }}
                </p>
              </b-media-body>
            </b-media>
            <b-media
              style="text-align: center;"
              v-if="onboardings.count === 0"
            >
              <span
                class="b-avatar badge-light-secondary rounded mb-2 mt-4"
                style="width: 70px; height: 70px;"
              >
                <feather-icon
                  icon="LogInIcon"
                  size="36"
                />
              </span>
              <h5>{{i18nT(`Nothing new`)}}</h5>
              <p class="text-muted"> {{i18nT(`There are no onboarding employees.`)}}</p>
            </b-media>
            <!--/ timeline -->
          </b-card-body>
          <b-link style="padding: 8px;" :to="{name: 'onboarding', params: {id: 0}}">
            <b-button
              variant="outline-secondary"
              style="width: 100%"
            >
              {{i18nT(`See all`)}}
            </b-button>
            </b-link>
        </b-card>
      </b-col>
      <b-col lg="4" v-if="iCan('offboarding', 'read')">
        <b-card no-body>
          <b-card-header>
            <b-card-title class="ml-25">
              {{offboardings.count}} {{i18nT(`Offboarding`)}}
            </b-card-title>
          </b-card-header>

          <!-- timeline -->
          <b-card-body>
            <b-media
              no-body
              v-for="offboarding in offboardings.items"
              :key="offboarding.Id"
              class="pb-50 pt-50 border-bottom"
            >
              <b-media-aside>
                <b-avatar
                  :src="offboarding.UserObj.ImageUrl"
                  :text="(offboarding.UserObj.FirstName ? offboarding.UserObj.FirstName.charAt(0) : '') + (offboarding.UserObj.LastName ? offboarding.UserObj.LastName.charAt(0) : '')"
                  :to="{
                      name: `view-employee`,
                      params: {id: offboarding.UserId, software: 'on'}
                  }"
                  class="badge-light-primary"
                  size="38"
                />
              </b-media-aside>
              <b-media-body class="my-auto">
                <a
                  :href="`/on/employees/view/`+offboarding.UserId"
                  class="font-weight-bold d-block text-nowrap"
                >
                  {{ offboarding.UserObj.Label }}
                </a>
                <p class="mb-0">
                  {{ offboarding.UserObj.Position }}
                </p>
              </b-media-body>
            </b-media>
            <b-media
              style="text-align: center;"
              v-if="offboardings.count === 0"
            >
              <span
                class="b-avatar badge-light-secondary rounded mb-2 mt-4"
                style="width: 70px; height: 70px;"
              >
                <feather-icon
                  icon="LogOutIcon"
                  size="36"
                />
              </span>
              <h5>{{i18nT(`Nothing new`)}}</h5>
              <p class="text-muted"> {{i18nT(`There are no offboarding employees.`)}}</p>
            </b-media>
            <!--/ timeline -->
          </b-card-body>
          <b-link style="padding: 8px;" :to="{name: 'offboarding', params: {id: 0}}">
            <b-button
              variant="outline-secondary"
              style="width: 100%"
            >
              {{i18nT(`See all`)}}
            </b-button>
          </b-link>
        </b-card>
      </b-col>
      <b-col lg="4">
        <analytics-messages
          :data="messages"
          manageLink="/on/inbox"
        />
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BCardHeader,
  BCardTitle,
  BMedia,
  BMediaBody,
  BMediaAside,
  BAvatar,
  BButton,
  BLink
} from 'bootstrap-vue'

import { kFormatter } from '@core/utils/filter'
import AnalyticsCongratulation from './analytics/AnalyticsCongratulation.vue'
import AnalyticsMessages from './analytics/AnalyticsMessages.vue'
import StatisticCardVertical from '@core/components/statistics-cards/StaticCardFA'
import _ from 'lodash'

export default {
  components: {
    BRow,
    BCol,
    AnalyticsCongratulation,
    BCard,
    StatisticCardVertical,
    AnalyticsMessages,
    BCardBody,
    BCardHeader,
    BCardTitle,
    BMedia,
    BMediaBody,
    BMediaAside,
    BAvatar,
    BButton,
    BLink
  },
  data() {
    return {
      data: {},
      onboardings: [],
      offboardings: [],
      messages: {
        messages: [],
        count: 0
      },
    }
  },
  created() {
    this.$http.get(`main/onDashboard`).then(({ data }) => {
      this.messages = {
        messages: data.data.messages,
        count: data.data.messages_count
      }
      this.offboardings = {
          items: data.data.offboarding,
            count: data.data.offboard_count
      }
      this.onboardings = {
        items: data.data.onboarding.filter((item) => {
            return item.UserObj
        }),
        count: data.data.onboard_count
      }


    })
  },
  methods: {
    kFormatter
  }
}
</script>
